import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const AIProcess = () => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: "50px 0px",
  });

  return (
    <ProcessSection ref={ref} id="process">
      <BackgroundGlow />
      <AnimatedGrid />
      <SectionTitle>{t('process.title')}</SectionTitle>
      <SectionSubtitle>{t('process.subtitle')}</SectionSubtitle>
      <ProcessGrid>
        {t('process.steps', { returnObjects: true }).map((step, index) => (
          <ProcessCard
            key={index}
            as={motion.div}
            initial={{ opacity: 1, y: 30 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 1, y: 30 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <CardGlow color={`linear-gradient(135deg, ${index % 2 ? '#58a6ff, #3bef7d' : '#3bef7d, #58a6ff'})`} />
            <IconWrapper isLast={index === t('process.steps', { returnObjects: true }).length - 1}>
              <IconBackground>{step.icon}</IconBackground>
              <ConnectorLine isLast={index === t('process.steps', { returnObjects: true }).length - 1} />
            </IconWrapper>
            <StepContent>
              <StepNumber>{step.phase}</StepNumber>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
              <FeaturesList>
                {step.features.map((feature, idx) => (
                  <FeatureItem key={idx}>
                    <FeatureIcon>✦</FeatureIcon>
                    {feature}
                  </FeatureItem>
                ))}
              </FeaturesList>
            </StepContent>
          </ProcessCard>
        ))}
      </ProcessGrid>
      <FloatingOrbs />
      <MetricsSection>
        <Metric>
          <MetricNumber>{t('metrics.development.number')}</MetricNumber>
          <MetricLabel>{t('metrics.development.label')}</MetricLabel>
        </Metric>
        <Metric>
          <MetricNumber>{t('metrics.uptime.number')}</MetricNumber>
          <MetricLabel>{t('metrics.uptime.label')}</MetricLabel>
        </Metric>
        <Metric>
          <MetricNumber>{t('metrics.support.number')}</MetricNumber>
          <MetricLabel>{t('metrics.support.label')}</MetricLabel>
        </Metric>
      </MetricsSection>
    </ProcessSection>
  );
};

const ProcessSection = styled.section`
  padding: 8rem 2rem;
  background: linear-gradient(to bottom, #0d1117, #1a1e24);
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  z-index: 1;
`;

const BackgroundGlow = styled.div`
  position: absolute;
  inset: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(88, 166, 255, 0.15), transparent 40%),
    radial-gradient(circle at 80% 80%, rgba(59, 239, 125, 0.15), transparent 40%);
  filter: blur(60px);
  z-index: 2;
  opacity: 0.8;
`;

const AnimatedGrid = styled.div`
  position: absolute;
  inset: 0;
  background-image: 
    linear-gradient(rgba(88, 166, 255, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(88, 166, 255, 0.05) 1px, transparent 1px);
  background-size: 50px 50px;
  transform: perspective(1000px) rotateX(60deg);
  animation: gridMove 20s linear infinite;
  opacity: 0.2;
  z-index: 1;

  @keyframes gridMove {
    0% { transform: perspective(1000px) rotateX(60deg) translateY(0); }
    100% { transform: perspective(1000px) rotateX(60deg) translateY(50px); }
  }
`;

const ProcessGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 900px;
  margin: 4rem auto 0;
  position: relative;
  z-index: 5;
  width: 100%;
  opacity: 1;
`;

const ProcessCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 24px;
  position: relative;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.4s ease;
  overflow: visible;
  z-index: 5;
  opacity: 1;
  display: flex;
  margin-bottom: 3rem;
  gap: 3rem;

  @media (max-width: 768px) {
    gap: 1.5rem;
    padding: 1.5rem 1rem;
    
    [dir="rtl"] & {
      gap: 0;
      padding: 1.5rem 1rem;
    }
  }

  &:hover {
    transform: translateY(-5px);
    border-color: rgba(88, 166, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }
`;

const CardGlow = styled.div`
  position: absolute;
  inset: 0;
  background: ${props => props.color};
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: -1;
  filter: blur(20px);

  ${ProcessCard}:hover & {
    opacity: 0.1;
  }
`;

const IconWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 80px;
  height: ${props => props.isLast ? '60px' : 'calc(100% + 3rem)'};
  padding-left: 1rem;
  display: flex;
  justify-content: center;
`;

const IconBackground = styled.div`
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  position: relative;
  z-index: 2;
  backdrop-filter: blur(5px);
`;

const ConnectorLine = styled.div`
  position: absolute;
  top: 60px;
  width: 2px;
  height: ${props => props.isLast ? '0' : 'calc(100% + 3rem)'};
  background: linear-gradient(135deg, rgba(88, 166, 255, 0.3), rgba(59, 239, 125, 0.3));
  z-index: 1;
`;

const StepContent = styled.div`
  flex: 1;
  position: relative;
  z-index: 3;
  padding-right: 1rem;
`;

const StepNumber = styled.span`
  font-size: 0.9rem;
  color: #58a6ff;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0.5rem;
  display: block;
`;

const StepTitle = styled.h3`
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #58a6ff, #3bef7d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StepDescription = styled.p`
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  font-size: 1.1rem;
`;

const FloatingOrbs = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(88, 166, 255, 0.1), transparent 70%);
    animation: floatOrb 20s infinite linear;
  }

  &::after {
    right: -100px;
    bottom: -100px;
    background: radial-gradient(circle, rgba(59, 239, 125, 0.1), transparent 70%);
    animation-delay: -10s;
  }

  @keyframes floatOrb {
    from { transform: rotate(0deg) translateX(100px) rotate(0deg); }
    to { transform: rotate(360deg) translateX(100px) rotate(-360deg); }
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #58a6ff, #3bef7d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 3;
  font-weight: 700;
`;

const MetricsSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: 6rem;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

const Metric = styled.div`
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 16px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    width: 160px;
    height: 160px;
    max-width: 160px;
    padding: 1.5rem;
  }
`;

const MetricNumber = styled.div`
  font-size: 3rem;
  font-weight: 700;
  background: linear-gradient(135deg, #58a6ff, #3bef7d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
`;

const MetricLabel = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 1.5rem;
`;

const FeatureItem = styled.li`
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
`;

const FeatureIcon = styled.span`
  color: #3bef7d;
  font-size: 1.2rem;
`;

const SectionSubtitle = styled.h3`
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 4rem;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 1px;
  position: relative;
  z-index: 3;
  max-width: 800px;
`;

export default AIProcess; 