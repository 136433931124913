import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <FooterSection>
      <FooterContainer>
        <FooterContent>
          <Copyright>
            {t('footer.copyright', { year: currentYear })}
          </Copyright>
          <SocialLinks>
            <SocialIcon
              as={motion.a}
              href="#"
              target="_blank"
              whileHover={{ y: -3 }}
              aria-label={t('footer.social.linkedin')}
            >
              <i className="fab fa-linkedin"></i>
            </SocialIcon>
            <SocialIcon
              as={motion.a}
              href="#"
              target="_blank"
              whileHover={{ y: -3 }}
              aria-label={t('footer.social.twitter')}
            >
              <i className="fab fa-twitter"></i>
            </SocialIcon>
          </SocialLinks>
        </FooterContent>
      </FooterContainer>
    </FooterSection>
  );
};

const FooterSection = styled.footer`
  background: var(--dark);
  color: white;
  padding: 2rem;
`;

const FooterContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }
`;

const Copyright = styled.p`
  color: #aaa;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
`;

const SocialIcon = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background: var(--secondary);
    transform: translateY(-3px);
  }

  i {
    font-size: 1.2rem;
  }
`;

export default Footer; 