export default {
  nav: {
    home: "الرئيسية",
    services: "خدماتنا",
    process: "منهجيتنا",
    contact: "اتصل بنا"
  },
  hero: {
    badge: "🇲🇦 أول وكالة تطوير مدعومة بالذكاء الاصطناعي في المغرب",
    title: {
      part1: "طور مشاريعك",
      part2: "بسرعة 10 أضعاف",
      part3: "مع الذكاء الاصطناعي"
    },
    description: "حول رؤيتك إلى واقع مع تقنيتنا المتطورة للذكاء الاصطناعي. من التصميم إلى النشر، نسرع كل خطوة في مشروعك مع ضمان جودة استثنائية وابتكار مستمر.",
    cta: "ابدأ مشروعك الآن",
    stats: {
      projects: "مشروع ناجح",
      clients: "عميل سعيد",
      support: "دعم فني"
    }
  },
  services: {
    title: "خدماتنا المسرّعة بالذكاء الاصطناعي",
    subtitle: "طوّر وأطلق مشروعك بسرعة ودقة لا مثيل لها",
    items: [
      {
        icon: '💻',
        title: 'تطوير ويب مسرّع بالذكاء الاصطناعي',
        description: 'طور موقعك الإلكتروني بسرعة 10 أضعاف باستخدام الذكاء الاصطناعي. تحلل أنظمتنا الذكية المتطلبات وتولد الكود المحسن وتضمن نتائج مثالية مع الحفاظ على أعلى معايير الجودة.',
        features: [
          'توليد الكود بالذكاء الاصطناعي',
          'اختبار وتحسين آلي',
          'تطوير أسرع 10 مرات'
        ]
      },
      {
        icon: '🚀',
        title: 'إنشاء MVP بقيادة الذكاء الاصطناعي',
        description: 'أطلق MVP في أيام وليس شهور. يحلل ذكاؤنا الاصطناعي متطلباتك، ويولد كوداً جاهزاً للإنتاج، ويساعد في التحقق من أفكارك بسرعة ودقة غير مسبوقة.',
        features: [
          'توليد سريع للنماذج الأولية',
          'تركيب ذكي للكود',
          'ضمان جودة آلي'
        ]
      },
      {
        icon: '🛠️',
        title: 'هندسة حلول ذكية',
        description: 'دع الذكاء الاصطناعي يصمم حلك المثالي. تحلل أنظمتنا المتطلبات، وتولد الهندسة المثلى، وتنشئ حلولاً قابلة للتطوير تتناسب تماماً مع احتياجات عملك.',
        features: [
          'تصميم هندسي بالذكاء الاصطناعي',
          'توليد آلي للكود',
          'تكامل نظام ذكي'
        ]
      }
    ]
  },
  process: {
    title: "منهجيتنا المدعومة بالذكاء الاصطناعي",
    subtitle: "نحول الأفكار إلى واقع بسرعة البرق",
    steps: [
      {
        icon: '🎯',
        title: 'التحليل الاستراتيجي',
        phase: 'المرحلة 1',
        description: 'يقوم ذكاؤنا الاصطناعي بتحليل فكرتك وإنشاء خارطة طريق واضحة في غضون ساعات، وليس أسابيع. احصل على رؤى فورية وخطة مفصلة لتحويل رؤيتك إلى واقع.',
        features: [
          'تحليل سريع للمشروع',
          'خطة تطوير واضحة',
          'توصيات تقنية'
        ]
      },
      {
        icon: '💡',
        title: 'التصميم والتخطيط',
        phase: 'المرحلة 2',
        description: 'يقوم فريقنا المتمرس بإنشاء تصاميم جميلة وسهلة الاستخدام تتناسب تماماً مع علامتك التجارية. نركز على إنشاء واجات سيحبها ��ستخدموك.',
        features: [
          'واجهة مستخدم جميلة',
          'تصميم يركز على الموبايل',
          'تركيز على تجربة المستخدم'
        ]
      },
      {
        icon: '⚡',
        title: 'التطوير السريع',
        phase: 'المرحلة 3',
        description: 'اختبر التطوير فائق السرعة مع أدواتنا المدعومة بالذكاء الاصطناعي. نقدم كوداً نظيفاً وقابلاً للتطوير، مصمماً للنمو مع عملك.',
        features: [
          'تسليم فائق السرعة',
          'كود عالي الجودة',
          'مصمم للتطور'
        ]
      },
      {
        icon: '🚀',
        title: 'الإطلاق والدعم',
        phase: 'المرحلة 4',
        description: 'نضمن إطلاق مشروعك بسلاسة واستمرار عمله بشكل مثالي. يتولى فريقنا جميع التفاصيل التقنية حتى تتمكن من التركيز على عملك.',
        features: [
          'إطلاق سلس',
          'مراقبة الأداء',
          'دعم موثوق'
        ]
      }
    ]
  },
  metrics: {
    development: {
      number: "10x",
      label: "تطوير أسرع"
    },
    uptime: {
      number: "100%",
      label: "عميل سعيد"
    },
    support: {
      number: "24/7",
      label: "دعم فني"
    }
  },
  cta_section: {
    title: "هل أنت جاهز لبدء رحلة النجاح الرقمي؟",
    description: "احصل على موقعك الإلكتروني في وقت قياسي مع تقنيات الذكاء الاصطناعي المتطورة. ابدأ اليوم واكتشف الفرق في جودة وسرعة التنفيذ!",
    button: "ابدأ مشروعك الآن"
  },
  contact: {
    title: "دعنا نبني شيئاً مميزاً معاً",
    description: "هل أنت مستعد لتحويل أفكارك إلى واقع؟ تواصل معنا اليوم لنناقش كيف يمكننا مساعدتك في تحقيق أهدافك من خلال عملية التطوير المدعومة بالذكاء الاصطناعي.",
    form: {
      name: {
        label: "الاسم",
        placeholder: "أدخل اسمك"
      },
      email: {
        label: "البريد الإلكتروني",
        placeholder: "أدخل بريدك الإلكتروني"
      },
      message: {
        label: "الرسالة",
        placeholder: "أخبرنا عن مشروعك"
      },
      submit: "إرسال الرسالة",
      validation: {
        name: {
          required: "الاسم مطلوب",
          min: "يجب أن يحتوي الاسم على حرفين على الأقل"
        },
        email: {
          required: "البريد الإلكتروني مطلوب",
          invalid: "يرجى إدخال بريد إلكتروني صحيح"
        },
        message: {
          required: "الرسالة مطلوبة",
          min: "يجب أن تحتوي الرسالة على 10 أحرف على الأقل"
        }
      },
      success: {
        title: "تم إرسال الرسالة بنجاح!",
        message: "شكراً لك على رسالتك. سنتواصل معك قريباً."
      },
      error: {
        sending: "خطأ في إرسال الرسالة. يرجى المحاولة مرة أخرى."
      },
      submitting: "جاري الإرسال...",
    },
    contact_info: {
      email: {
        label: "راسلنا عبر البريد",
        value: "contact@webox.ma"
      }
    }
  },
  footer: {
    copyright: "© 2024 جميع الحقوق محفوظة ويبكس.ما",
    social: {
      linkedin: "تابعنا ع��ى لينكد إن",
      twitter: "تابعنا على تويتر"
    }
  }
}; 