import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './translations/en';
import fr from './translations/fr';
import ar from './translations/ar';

const resources = {
  en: { translation: en },
  fr: { translation: fr },
  ar: { translation: ar }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
  });

// Set initial direction based on detected language
const setInitialDirection = () => {
  const currentLang = i18n.language || window.localStorage.i18nextLng || 'en';
  const direction = currentLang === 'ar' ? 'rtl' : 'ltr';
  document.documentElement.dir = direction;
  document.documentElement.lang = currentLang;
};

// Set direction on initial load
setInitialDirection();

// Listen for language changes
i18n.on('languageChanged', (lng) => {
  const direction = lng === 'ar' ? 'rtl' : 'ltr';
  document.documentElement.dir = direction;
  document.documentElement.lang = lng;
});

export default i18n; 