import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import { toast, Toaster } from 'react-hot-toast';
import * as yup from 'yup';
import { EMAIL_CONFIG } from '../config/email';

// Add new template ID for auto-response
const AUTO_RESPONSE_TEMPLATE_ID = 'template_webox_client'; // Replace with your auto-response template ID

const Contact = () => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Form validation schema
  const validationSchema = yup.object().shape({
    name: yup.string()
      .required(t('contact.form.validation.name.required'))
      .min(2, t('contact.form.validation.name.min')),
    email: yup.string()
      .required(t('contact.form.validation.email.required'))
      .email(t('contact.form.validation.email.invalid')),
    message: yup.string()
      .required(t('contact.form.validation.message.required'))
      .min(10, t('contact.form.validation.message.min')),
  });

  const validateField = async (name, value) => {
    try {
      await yup.reach(validationSchema, name).validate(value);
      setErrors(prev => ({ ...prev, [name]: undefined }));
    } catch (error) {
      setErrors(prev => ({ ...prev, [name]: error.message }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Validate all fields
      await validationSchema.validate(formData, { abortEarly: false });

      // Send email to admin using EmailJS
      const adminResponse = await emailjs.send(
        EMAIL_CONFIG.SERVICE_ID,
        EMAIL_CONFIG.TEMPLATE_ID,
        {
          from_name: formData.name,
          from_email: formData.email,
          message: formData.message,
        },
        EMAIL_CONFIG.PUBLIC_KEY
      );

      // Send auto-response email to user
      const userResponse = await emailjs.send(
        EMAIL_CONFIG.SERVICE_ID,
        AUTO_RESPONSE_TEMPLATE_ID,
        {
          to_name: formData.name,
          to_email: formData.email,
          message: formData.message,
        },
        EMAIL_CONFIG.PUBLIC_KEY
      );

      if (adminResponse.status === 200 && userResponse.status === 200) {
        // Get translated success messages
        const successTitle = t('contact.form.success.title');
        const successMessage = t('contact.form.success.message');

        // Show success toast with updated styling
        toast.custom(() => (
          <SuccessToast>
            <ToastIcon>✓</ToastIcon>
            <ToastContent>
              <ToastTitle>{successTitle}</ToastTitle>
              <ToastMessage>{successMessage}</ToastMessage>
            </ToastContent>
          </SuccessToast>
        ), {
          duration: 5000,
        });

        // Reset form
        setFormData({ name: '', email: '', message: '' });
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        // Handle validation errors
        const newErrors = {};
        error.inner.forEach(err => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
      } else {
        // Handle EmailJS errors
        toast.error(t('contact.form.error.sending'), {
          duration: 5000,
          style: {
            background: '#ffffff',
            color: '#1a1e24',
            padding: '16px',
            borderRadius: '12px',
            border: '2px solid transparent',
            backgroundImage: 'linear-gradient(#ffffff, #ffffff), linear-gradient(135deg, #ff4d4d, #ff8f8f)',
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box',
            boxShadow: '0 4px 15px rgba(255, 77, 77, 0.2), 0 0 0 2px rgba(255, 255, 255, 1) inset',
            fontWeight: '500',
          },
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ContactSection id="contact">
      <Toaster position="top-center" />
      <BackgroundGlow />
      <ContactContainer>
        <ContactInfo>
          <SectionTitle>{t('contact.title')}</SectionTitle>
          <Description>
            {t('contact.description')}
          </Description>
          <ContactDetails>
            <ContactItem>
              <Icon>📧</Icon>
              <div>
                <strong>{t('contact.contact_info.email.label')}</strong>
                <p>{t('contact.contact_info.email.value')}</p>
              </div>
            </ContactItem>
          </ContactDetails>
        </ContactInfo>
        <ContactForm onSubmit={handleSubmit}>
          <FormGroup>
            <label>{t('contact.form.name.label')}</label>
            <input 
              type="text" 
              name="name"
              placeholder={t('contact.form.name.placeholder')} 
              onChange={handleChange}
              value={formData.name}
              disabled={isSubmitting}
              className={errors.name ? 'error' : ''}
            />
            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
          </FormGroup>
          <FormGroup>
            <label>{t('contact.form.email.label')}</label>
            <input 
              type="email" 
              name="email"
              placeholder={t('contact.form.email.placeholder')} 
              onChange={handleChange}
              value={formData.email}
              disabled={isSubmitting}
              className={errors.email ? 'error' : ''}
            />
            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
          </FormGroup>
          <FormGroup>
            <label>{t('contact.form.message.label')}</label>
            <textarea 
              name="message"
              placeholder={t('contact.form.message.placeholder')} 
              onChange={handleChange}
              value={formData.message}
              disabled={isSubmitting}
              className={errors.message ? 'error' : ''}
            ></textarea>
            {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
          </FormGroup>
          <SubmitButton 
            type="submit" 
            disabled={isSubmitting}
          >
            {isSubmitting ? t('contact.form.submitting') : t('contact.form.submit')} 
            <span>→</span>
          </SubmitButton>
        </ContactForm>
      </ContactContainer>
    </ContactSection>
  );
};

const ContactSection = styled.section`
  padding: 8rem 2rem;
  background: linear-gradient(to bottom, #0d1117, #1a1e24);
  position: relative;
  overflow: hidden;
`;

const ContactContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const ContactInfo = styled.div``;

const SectionTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #58a6ff, #3bef7d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
`;

const Description = styled.p`
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  margin-bottom: 2rem;
  font-size: 1.1rem;
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  div {
    strong {
      display: block;
      color: #58a6ff;
      margin-bottom: 0.2rem;
      font-size: 1.1rem;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

const Icon = styled.span`
  font-size: 1.5rem;
  color: #3bef7d;
`;

const ContactForm = styled.form`
  background: rgba(255, 255, 255, 0.05);
  padding: 2.5rem;
  border-radius: 24px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.4s ease;

  &:hover {
    border-color: rgba(88, 166, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: rgba(255, 255, 255, 0.9);
  }

  input, textarea {
    width: 100%;
    padding: 0.8rem 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.05);
    color: white;
    font-size: 1rem;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: #58a6ff;
      background: rgba(255, 255, 255, 0.08);
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }

    &.error {
      border-color: #ff4d4d;
      background: rgba(255, 77, 77, 0.05);
    }
  }

  textarea {
    min-height: 120px;
    resize: vertical;
  }
`;

const SubmitButton = styled.button`
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #58a6ff, #3bef7d);
  color: white;
  border: none;
  border-radius: 25px;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(88, 166, 255, 0.2);
  width: 100%;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(88, 166, 255, 0.3);
  }

  span {
    margin-left: 0.5rem;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }
`;

const BackgroundGlow = styled.div`
  position: absolute;
  inset: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(88, 166, 255, 0.15), transparent 40%),
    radial-gradient(circle at 80% 80%, rgba(59, 239, 125, 0.15), transparent 40%);
  filter: blur(60px);
  z-index: 1;
  opacity: 0.8;
`;

const ErrorMessage = styled.span`
  color: #ff4d4d;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  display: block;
`;

const SuccessToast = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 16px;
  border-radius: 12px;
  border: 2px solid transparent;
  background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(135deg, #58a6ff, #3bef7d);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: 0 4px 15px rgba(88, 166, 255, 0.2), 0 0 0 2px rgba(255, 255, 255, 1) inset;
`;

const ToastIcon = styled.div`
  background: linear-gradient(135deg, #58a6ff, #3bef7d);
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  flex-shrink: 0;
`;

const ToastContent = styled.div`
  flex: 1;
`;

const ToastTitle = styled.h4`
  margin: 0 0 4px 0;
  color: #1a1e24;
  font-weight: 600;
  font-size: 1rem;
`;

const ToastMessage = styled.p`
  margin: 0;
  color: #4a5568;
  font-size: 0.875rem;
`;

export default Contact; 