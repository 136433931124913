import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import AIProcess from './components/AIProcess';
import CTASection from './components/CTASection';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { GlobalStyles } from './styles/GlobalStyles';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Set direction on component mount
    const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.dir = direction;
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <>
      <GlobalStyles />
      <Navbar />
      <main className="main-content">
        <Hero />
        <Services />
        <AIProcess />
        <CTASection />
        <Contact />
        <Footer />
      </main>
    </>
  );
}

export default App; 