import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <ServicesSection ref={ref} id="services">
      <BackgroundGlow />
      <SectionTitle>{t('services.title')}</SectionTitle>
      <SectionSubtitle>{t('services.subtitle')}</SectionSubtitle>
      <ServiceGrid>
        {t('services.items', { returnObjects: true }).map((service, index) => (
          <ServiceCard
            key={index}
            as={motion.div}
            initial={{ opacity: 0, y: 30 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <CardGlow />
            <IconContainer>{service.icon}</IconContainer>
            <ServiceTitle>{service.title}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
            <FeatureList>
              {service.features.map((feature, idx) => (
                <FeatureItem key={idx}>
                  <FeatureIcon>✓</FeatureIcon>
                  {feature}
                </FeatureItem>
              ))}
            </FeatureList>
          </ServiceCard>
        ))}
      </ServiceGrid>
    </ServicesSection>
  );
};

const ServicesSection = styled.section`
  padding: 8rem 2rem;
  background: linear-gradient(
    to bottom,
    #ffffff,
    #f8faff 50%,
    #ffffff
  );
  position: relative;
  overflow: hidden;
  isolation: isolate;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at 0% 0%,
      rgba(88, 166, 255, 0.03),
      transparent 50%
    );
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at 100% 100%,
      rgba(59, 239, 125, 0.03),
      transparent 50%
    );
    z-index: -1;
  }
`;

const BackgroundGlow = styled.div`
  position: absolute;
  inset: 0;
  z-index: -2;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    background: radial-gradient(
      circle,
      rgba(88, 166, 255, 0.05) 0%,
      transparent 70%
    );
    top: -25%;
    left: -25%;
    animation: floatGlow 15s infinite linear;
  }

  &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    background: radial-gradient(
      circle,
      rgba(59, 239, 125, 0.05) 0%,
      transparent 70%
    );
    bottom: -25%;
    right: -25%;
    animation: floatGlow 15s infinite linear reverse;
  }

  @keyframes floatGlow {
    0% {
      transform: rotate(0deg) translate(100px) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translate(100px) rotate(-360deg);
    }
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #58a6ff, #3bef7d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  font-weight: 700;
  line-height: 1.2;
`;

const SectionSubtitle = styled.h3`
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 4rem;
  color: #4a5568;
  letter-spacing: 1px;
  position: relative;
  text-transform: none;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled.div`
  background: rgba(255, 255, 255, 0.7);
  padding: 2.5rem;
  border-radius: 24px;
  position: relative;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(88, 166, 255, 0.1);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06),
    0 0 0 1px rgba(255, 255, 255, 0.3) inset;

  &:hover {
    transform: translateY(-5px) scale(1.02);
    border-color: rgba(88, 166, 255, 0.3);
    box-shadow: 
      0 20px 40px rgba(0, 0, 0, 0.05),
      0 0 20px rgba(88, 166, 255, 0.1),
      0 0 0 1px rgba(255, 255, 255, 0.5) inset;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    );
    transform: translateX(-100%);
    transition: transform 0.8s;
  }

  &:hover::before {
    transform: translateX(100%);
  }
`;

const CardGlow = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    rgba(88, 166, 255, 0.1),
    rgba(59, 239, 125, 0.1)
  );
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: -1;

  ${ServiceCard}:hover & {
    opacity: 1;
  }
`;

const IconContainer = styled.div`
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
`;

const ServiceTitle = styled.h3`
  font-size: 1.8rem;
  color: #1a1e24;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
`;

const ServiceDescription = styled.p`
  color: #4a5568;
  line-height: 1.7;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  position: relative;
  z-index: 1;
`;

const FeatureItem = styled.li`
  color: #2d3748;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.1rem;
`;

const FeatureIcon = styled.span`
  color: #3bef7d;
  font-weight: bold;
`;

export default Services; 