import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const languages = [
    { code: 'en', label: 'English', dir: 'ltr' },
    { code: 'fr', label: 'Français', dir: 'ltr' },
    { code: 'ar', label: 'العربية', dir: 'rtl' }
  ];

  const navItems = [
    { to: "hero", label: t('nav.home') },
    { to: "services", label: t('nav.services') },
    { to: "process", label: t('nav.process') },
    { to: "contact", label: t('nav.contact') }
  ];

  const handleLanguageChange = (langCode) => {
    i18n.changeLanguage(langCode);
    document.documentElement.dir = languages.find(lang => lang.code === langCode).dir;
    setIsLangMenuOpen(false);
  };

  return (
    <Nav isScrolled={isScrolled}>
      <NavContainer className="navbar-container">
        <Logo>
          Webox<Dot>.</Dot>ma
        </Logo>
        <NavContent>
          <DesktopMenu>
            {navItems.map((item) => (
              <NavItem key={item.to}>
                <NavLink
                  to={item.to}
                  spy={true}
                  smooth={false}
                  duration={0}
                  offset={-80}
                >
                  {item.label}
                </NavLink>
              </NavItem>
            ))}
          </DesktopMenu>
          <LanguageSwitch>
            <CurrentLang onClick={() => setIsLangMenuOpen(!isLangMenuOpen)}>
              {languages.find(lang => lang.code === i18n.language)?.label || 'English'} <span>▼</span>
            </CurrentLang>
            {isLangMenuOpen && (
              <LangDropdown>
                {languages.map((lang) => (
                  <LangOption
                    key={lang.code}
                    onClick={() => handleLanguageChange(lang.code)}
                    isActive={i18n.language === lang.code}
                  >
                    {lang.label}
                  </LangOption>
                ))}
              </LangDropdown>
            )}
          </LanguageSwitch>
        </NavContent>
      </NavContainer>
    </Nav>
  );
};

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: ${props => props.isScrolled ? 'rgba(13, 17, 23, 0.8)' : 'transparent'};
  backdrop-filter: ${props => props.isScrolled ? 'blur(20px)' : 'none'};
  border-bottom: ${props => props.isScrolled ? '1px solid rgba(88, 166, 255, 0.1)' : 'none'};
  transition: all 0.3s ease;
`;

const NavContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #58a6ff, #3bef7d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Dot = styled.span`
  color: #58a6ff;
  -webkit-text-fill-color: #58a6ff;
`;

const DesktopMenu = styled.ul`
  display: flex;
  gap: 2.5rem;
  align-items: center;
  list-style: none;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
  position: relative;
`;

const NavLink = styled(Link)`
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0.5rem 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(135deg, #58a6ff, #3bef7d);
    transition: width 0.3s ease;
    border-radius: 2px;
  }

  &:hover, &.active {
    color: #fff;
    
    &::after {
      width: 100%;
    }
  }
`;

const NavContent = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const LanguageSwitch = styled.div`
  position: relative;
  z-index: 1000;
`;

const CurrentLang = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;

  span {
    font-size: 0.8rem;
    opacity: 0.7;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
  }
`;

const LangDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background: rgba(26, 30, 36, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 0.5rem;
  backdrop-filter: blur(10px);
  min-width: 120px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
`;

const LangOption = styled.button`
  width: 100%;
  padding: 0.5rem 1rem;
  background: ${props => props.isActive ? 'rgba(88, 166, 255, 0.1)' : 'transparent'};
  border: none;
  color: ${props => props.isActive ? '#58a6ff' : 'rgba(255, 255, 255, 0.8)'};
  text-align: left;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(88, 166, 255, 0.1);
    color: #58a6ff;
  }
`;

export default Navbar;