export default {
  nav: {
    home: "Accueil",
    services: "Services",
    process: "Processus",
    contact: "Contact"
  },
  hero: {
    badge: "Première Agence de Développement IA au Maroc 🇲🇦",
    title: {
      part1: "Innovez et Développez",
      part2: "10x Plus Rapidement",
      part3: "avec l'IA"
    },
    description: "Transformez votre vision en réalité avec notre technologie IA de pointe. De la conception au déploiement, nous accélérons chaque étape de votre projet tout en garantissant une qualité exceptionnelle et une innovation constante.",
    cta: "Lancez Votre Projet",
    stats: {
      projects: "Projets Réussis",
      clients: "Clients Satisfaits",
      support: "Assistance Technique"
    }
  },
  services: {
    title: "Nos Services Accélérés par l'IA",
    subtitle: "Développez et Lancez Plus Rapidement avec une Précision Inégalée",
    items: [
      {
        icon: "💻",
        title: "Développement Web Accéléré par l'IA",
        description: "Développez votre site web 10x plus rapidement grâce à l'IA. Nos systèmes intelligents analysent les besoins, génèrent du code optimisé et garantissent des résultats parfaits tout en maintenant les plus hauts standards de qualité.",
        features: [
          "Génération de Code par l'IA",
          "Tests et Optimisation Automatisés",
          "Développement 10x Plus Rapide"
        ]
      },
      {
        icon: "🚀",
        title: "Création MVP Guidée par l'IA",
        description: "Lancez votre MVP en quelques jours, pas en mois. Notre IA analyse vos besoins, génère du code prêt pour la production et aide à valider vos idées avec une rapidité et une précision sans précédent.",
        features: [
          "Génération Rapide de Prototypes",
          "Synthèse Intelligente de Code",
          "Assurance Qualité Automatisée"
        ]
      },
      {
        icon: "🛠️",
        title: "Architecture de Solutions Intelligente",
        description: "Laissez l'IA concevoir votre solution parfaite. Nos systèmes analysent les besoins, génèrent une architecture optimale et créent des solutions évolutives qui correspondent parfaitement à vos besoins.",
        features: [
          "Conception d'Architecture par l'IA",
          "Génération Automatique de Code",
          "Intégration Système Intelligente"
        ]
      }
    ]
  },
  process: {
    title: "Notre Processus Propulsé par l'IA",
    subtitle: "Transformer vos Idées en Réalité à la Vitesse de l'Éclair",
    steps: [
      {
        icon: "🎯",
        title: "Analyse Stratégique",
        phase: "Phase 1",
        description: "Notre IA analyse votre idée et crée une feuille de route claire en quelques heures, pas en semaines. Obtenez des insights instantanés et un plan détaillé pour concrétiser votre vision.",
        features: [
          "Analyse Rapide du Projet",
          "Plan de Développement Clair",
          "Recommandations Technologiques"
        ]
      },
      {
        icon: "💡",
        title: "Design & Planification",
        phase: "Phase 2",
        description: "Notre équipe expérimentée crée des designs élégants et conviviaux qui correspondent parfaitement à votre marque. Nous nous concentrons sur la création d'interfaces que vos utilisateurs adoreront.",
        features: [
          "Interface Utilisateur Élégante",
          "Design Mobile-First",
          "Focus sur l'Expérience Utilisateur"
        ]
      },
      {
        icon: "⚡",
        title: "Développement Rapide",
        phase: "Phase 3",
        description: "Expérimentez un développement ultra-rapide avec nos outils propulsés par l'IA. Nous livrons un code propre et évolutif, conçu pour grandir avec votre entreprise.",
        features: [
          "Livraison Ultra Rapide",
          "Code de Qualité",
          "Conçu pour Évoluer"
        ]
      },
      {
        icon: "🚀",
        title: "Lancement & Support",
        phase: "Phase 4",
        description: "Nous assurons un lancement en douceur de votre projet et veillons à son fonctionnement optimal. Notre équipe gère tous les détails techniques pour que vous puissiez vous concentrer sur votre activité.",
        features: [
          "Lancement en Douceur",
          "Surveillance des Performances",
          "Support Fiable"
        ]
      }
    ]
  },
  metrics: {
    development: {
      number: "10x",
      label: "Plus Rapide"
    },
    uptime: {
      number: "100%",
      label: "Clients Satisfaits"
    },
    support: {
      number: "24/7",
      label: "Assistance Technique"
    }
  },
  cta_section: {
    title: "Prêt à Commencer Votre Transformation Digitale ?",
    description: "Développez votre site web en un temps record grâce à notre processus de développement propulsé par l'IA. Une technologie de pointe au service de votre réussite !",
    button: "Lancer Votre Projet"
  },
  contact: {
    title: "Créons Ensemble Quelque Chose d'Extraordinaire",
    description: "Prêt à transformer vos idées en réalité ? Contactez-nous dès aujourd'hui pour discuter de la façon dont nous pouvons vous aider à atteindre vos objectifs grâce à notre processus de développement propulsé par l'IA.",
    form: {
      name: {
        label: "Nom",
        placeholder: "Votre nom"
      },
      email: {
        label: "Email",
        placeholder: "Votre email"
      },
      message: {
        label: "Message",
        placeholder: "Parlez-nous de votre projet"
      },
      submit: "Envoyer le Message",
      validation: {
        name: {
          required: "Le nom est requis",
          min: "Le nom doit contenir au moins 2 caractères"
        },
        email: {
          required: "L'email est requis",
          invalid: "Veuillez entrer une adresse email valide"
        },
        message: {
          required: "Le message est requis",
          min: "Le message doit contenir au moins 10 caractères"
        }
      },
      success: {
        title: "Message Envoyé avec Succès !",
        message: "Merci pour votre message. Nous vous répondrons bientôt."
      },
      error: {
        sending: "Erreur lors de l'envoi du message. Veuillez réessayer."
      },
      submitting: "Envoi en cours...",
    },
    contact_info: {
      email: {
        label: "Contactez-nous",
        value: "contact@webox.ma"
      }
    }
  },
  footer: {
    copyright: "© 2024 Webox.ma. Tous droits réservés",
    social: {
      linkedin: "Suivez-nous sur LinkedIn",
      twitter: "Suivez-nous sur Twitter"
    }
  }
}; 