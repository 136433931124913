import styled from '@emotion/styled';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const CTASection = () => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const scrollToContact = () => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };

  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [0.8, 1]);

  return (
    <CTASectionWrapper ref={ref} id="cta">
      <CTAContainer
        as={motion.div}
        style={{ scale }}
      >
        <CTACard>
          <Cube>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Cube>
          <CTAContent>
            <CTATitle>{t('cta_section.title')}</CTATitle>
            <CTADescription>
              {t('cta_section.description')}
            </CTADescription>
            <PrimaryButton
              as={motion.button}
              onClick={scrollToContact}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t('cta_section.button')} <span>→</span>
            </PrimaryButton>
          </CTAContent>
        </CTACard>
      </CTAContainer>
    </CTASectionWrapper>
  );
};

const CTASectionWrapper = styled.section`
  padding: 5rem 2rem;
  background: #ffffff;
  overflow: hidden;
  position: relative;
`;

const CTAContainer = styled.div`
  margin-top: 0rem;
  padding: 0 2rem;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const CTACard = styled.div`
  background: #ffffff;
  border-radius: 24px;
  padding: 3rem;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(88, 166, 255, 0.1);
  position: relative;
  overflow: visible;
  transition: all 0.4s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    padding: 1.5rem;
    flex-direction: column;
    text-align: center;
  }

  &:hover {
    border-color: rgba(88, 166, 255, 0.3);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }
`;

const CTAContent = styled.div`
  flex: 1;
  z-index: 1;
  padding-left: 0;
  padding-right: 3rem;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const CTATitle = styled.h3`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #58a6ff, #3bef7d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
`;

const CTADescription = styled.p`
  color: #4a5568;
  margin-bottom: 2rem;
  line-height: 1.6;
  font-size: 1.1rem;
  max-width: 600px;
`;

const PrimaryButton = styled.button`
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #58a6ff, #3bef7d);
  color: white;
  border: none;
  border-radius: 25px;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(88, 166, 255, 0.2);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(88, 166, 255, 0.3);
  }

  span {
    margin-left: 0.5rem;
  }
`;

const Cube = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  transform-style: preserve-3d;
  animation: rotate 20s infinite linear;
  flex-shrink: 0;

  // Outer cube faces
  &::before,
  &::after,
  & > span {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, 
      rgba(88, 166, 255, 0.2), 
      rgba(59, 239, 125, 0.2)
    );
  }

  &::before {
    transform: rotateY(0deg) translateZ(100px);
    background: linear-gradient(135deg, 
      rgba(88, 166, 255, 0.15), 
      rgba(59, 239, 125, 0.15)
    );
  }

  &::after {
    transform: rotateY(90deg) translateZ(100px);
    background: linear-gradient(45deg, 
      rgba(59, 239, 125, 0.1), 
      rgba(88, 166, 255, 0.1)
    );
  }

  & > span:nth-of-type(1) {
    transform: rotateY(180deg) translateZ(100px);
    background: linear-gradient(225deg, 
      rgba(88, 166, 255, 0.12), 
      rgba(59, 239, 125, 0.12)
    );
  }

  & > span:nth-of-type(2) {
    transform: rotateY(-90deg) translateZ(100px);
    background: linear-gradient(315deg, 
      rgba(59, 239, 125, 0.13), 
      rgba(88, 166, 255, 0.13)
    );
  }

  & > span:nth-of-type(3) {
    transform: rotateX(90deg) translateZ(100px);
    background: linear-gradient(135deg, 
      rgba(88, 166, 255, 0.08), 
      rgba(59, 239, 125, 0.08)
    );
  }

  & > span:nth-of-type(4) {
    transform: rotateX(-90deg) translateZ(100px);
    background: linear-gradient(45deg, 
      rgba(59, 239, 125, 0.11), 
      rgba(88, 166, 255, 0.11)
    );
  }

  // Inner cube
  & > div {
    position: absolute;
    width: 40%;
    height: 40%;
    top: 30%;
    left: 30%;
    transform-style: preserve-3d;
    animation: rotateReverse 15s infinite linear;
  }

  & > div::before,
  & > div::after,
  & > div > span {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, 
      rgba(88, 166, 255, 0.15), 
      rgba(59, 239, 125, 0.15)
    );
  }

  & > div::before {
    transform: rotateY(0deg) translateZ(40px);
  }

  & > div::after {
    transform: rotateY(90deg) translateZ(40px);
  }

  & > div > span:nth-of-type(1) {
    transform: rotateY(180deg) translateZ(40px);
  }

  & > div > span:nth-of-type(2) {
    transform: rotateY(-90deg) translateZ(40px);
  }

  & > div > span:nth-of-type(3) {
    transform: rotateX(90deg) translateZ(40px);
  }

  & > div > span:nth-of-type(4) {
    transform: rotateX(-90deg) translateZ(40px);
  }

  @keyframes rotate {
    from {
      transform: rotateY(0deg) rotateX(0deg);
    }
    to {
      transform: rotateY(360deg) rotateX(360deg);
    }
  }

  @keyframes rotateReverse {
    from {
      transform: rotateY(0deg) rotateX(0deg);
    }
    to {
      transform: rotateY(-360deg) rotateX(-360deg);
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export default CTASection; 