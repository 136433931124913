import React from 'react';
import { Global, css } from '@emotion/react';

export const GlobalStyles = () => (
  <Global
    styles={css`
      :root {
        --primary: #2E3192;
        --secondary: #00B4DB;
        --dark: #1A1A1A;
        --light: #FFFFFF;
        --gradient: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
      }

      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
      }

      body {
        background-color: var(--light);
        color: var(--dark);
        overflow-x: hidden;
        transition: all 0.3s ease;
      }

      html {
        scroll-behavior: smooth;
      }

      /* RTL Support */
      [dir="rtl"] {
        /* Exclude navbar from RTL */
        .main-content {
          direction: rtl;
          font-family: 'Noto Sans Arabic', sans-serif;
        }
        
        /* Reset navbar direction */
        .navbar-container {
          direction: ltr;
          font-family: 'Poppins', sans-serif;
        }
      }
    `}
  />
); 