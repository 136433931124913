export default {
  nav: {
    home: "Home",
    services: "Services",
    process: "Process",
    contact: "Contact"
  },
  hero: {
    badge: "First AI-Powered Dev Agency in Morocco",
    title: {
      part1: "Build Faster with",
      part2: "AI-Powered",
      part3: "Development"
    },
    description: "Leverage AI to build your next project 10x faster. From concept to deployment, our AI accelerates every step while maintaining exceptional quality.",
    cta: "Start Your Project",
    stats: {
      projects: "Successful Projects",
      clients: "Satisfied Clients",
      support: "Technical Support"
    }
  },
  services: {
    title: "Our AI-Accelerated Services",
    subtitle: "Leverage AI to Build and Launch Faster with Unmatched Precision",
    items: [
      {
        icon: '💻',
        title: 'AI-Accelerated Web Development',
        description: 'Experience 10x faster web development powered by AI. Our intelligent systems analyze requirements, generate optimized code, and ensure pixel-perfect results while maintaining the highest quality standards.',
        features: [
          'AI-Powered Code Generation',
          'Automated Testing & Optimization',
          '10x Faster Development Cycle'
        ]
      },
      {
        icon: '🚀',
        title: 'AI-Driven MVP Creation',
        description: 'Launch your MVP in days, not months. Our AI analyzes your requirements, generates production-ready code, and helps validate your ideas with unprecedented speed and precision.',
        features: [
          'Rapid Prototype Generation',
          'Intelligent Code Synthesis',
          'Automated Quality Assurance'
        ]
      },
      {
        icon: '🛠️',
        title: 'Smart Solution Architecture',
        description: 'Let AI design your perfect solution. Our systems analyze requirements, generate optimal architecture, and create scalable solutions that perfectly match your business needs.',
        features: [
          'AI Architecture Design',
          'Automated Code Generation',
          'Intelligent System Integration'
        ]
      }
    ]
  },
  process: {
    title: "Our AI-Powered Process",
    subtitle: "Transforming Ideas into Reality at Lightning Speed",
    steps: [
      {
        icon: '🎯',
        title: 'Strategic Analysis',
        phase: 'Phase 1',
        description: 'We use AI to analyze your idea and create a clear project roadmap within hours, not weeks. Get instant insights and a detailed plan to bring your vision to life.',
        features: [
          'Quick Project Analysis',
          'Clear Development Plan',
          'Technology Recommendations'
        ]
      },
      {
        icon: '💡',
        title: 'Design & Planning',
        phase: 'Phase 2',
        description: 'Our experienced team creates beautiful, user-friendly designs that perfectly match your brand. We focus on creating interfaces your users will love.',
        features: [
          'Beautiful User Interface',
          'Mobile-First Design',
          'User Experience Focus'
        ]
      },
      {
        icon: '⚡',
        title: 'Rapid Development',
        phase: 'Phase 3',
        description: "Experience lightning-fast development with our AI-powered tools. We deliver clean, scalable code that's built to grow with your business.",
        features: [
          'Super Fast Delivery',
          'Quality Code',
          'Built to Scale'
        ]
      },
      {
        icon: '🚀',
        title: 'Launch & Support',
        phase: 'Phase 4',
        description: 'We ensure your project launches smoothly and stays running perfectly. Our team handles all the technical details so you can focus on your business.',
        features: [
          'Smooth Launch',
          'Performance Monitoring',
          'Reliable Support'
        ]
      }
    ]
  },
  metrics: {
    development: {
      number: "10x",
      label: "Faster Development"
    },
    uptime: {
      number: "100%",
      label: "Satisfied Clients"
    },
    support: {
      number: "24/7",
      label: "Technical Support"
    }
  },
  cta_section: {
    title: "Ready to Start Your Journey?",
    description: "Get your website built in record time with our AI-powered development process. Start today and see the difference!",
    button: "Start Your Project"
  },
  contact: {
    title: "Let's Build Something Amazing Together",
    description: "Ready to transform your ideas into reality? Get in touch with us today and let's discuss how we can help you achieve your goals with our AI-powered development process.",
    form: {
      name: {
        label: "Name",
        placeholder: "Your name"
      },
      email: {
        label: "Email",
        placeholder: "Your email"
      },
      message: {
        label: "Message",
        placeholder: "Tell us about your project"
      },
      submit: "Send Message",
      validation: {
        name: {
          required: "Name is required",
          min: "Name must be at least 2 characters"
        },
        email: {
          required: "Email is required",
          invalid: "Please enter a valid email address"
        },
        message: {
          required: "Message is required",
          min: "Message must be at least 10 characters"
        }
      },
      success: {
        title: "Message Sent Successfully!",
        message: "Thank you for your message. We'll get back to you soon."
      },
      error: {
        sending: "Error sending message. Please try again."
      },
      submitting: "Sending...",
    },
    contact_info: {
      email: {
        label: "Email Us",
        value: "contact@webox.ma"
      }
    }
  },
  footer: {
    copyright: "© 2024 Webox.ma. All rights reserved",
    social: {
      linkedin: "Follow us on LinkedIn",
      twitter: "Follow us on Twitter"
    }
  }
}; 