import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t, i18n } = useTranslation();
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const techStacks = {
    frontend: [
      'React', 'Vue', 'Angular', 'Svelte', 'Next.js',
      'Nuxt', 'Gatsby', 'Remix', 'Solid'
    ],
    backend: [
      'Node.js', 'Python', 'Java', 'PHP', 'Go',
      'Ruby', 'C#', 'Rust', 'Kotlin'
    ],
    database: [
      'MongoDB', 'PostgreSQL', 'MySQL', 'Firebase',
      'Redis', 'Supabase', 'DynamoDB', 'Cassandra'
    ]
  };

  const [currentTech, setCurrentTech] = useState({
    frontend: 0,
    backend: 0,
    database: 0
  });

  const [isTyping, setIsTyping] = useState({
    frontend: false,
    backend: false,
    database: false
  });

  const [displayedText, setDisplayedText] = useState({
    frontend: techStacks.frontend[0],
    backend: techStacks.backend[0],
    database: techStacks.database[0]
  });

  useEffect(() => {
    const intervals = {};
    const typingSpeed = 50; // ms per character

    Object.keys(techStacks).forEach((stack, index) => {
      intervals[stack] = setInterval(() => {
        setCurrentTech(prev => {
          const nextIndex = (prev[stack] + 1) % techStacks[stack].length;
          setIsTyping(typing => ({ ...typing, [stack]: true }));

          const currentText = techStacks[stack][prev[stack]];
          const targetText = techStacks[stack][nextIndex];

          // Erase current text
          let i = currentText.length;
          const eraseInterval = setInterval(() => {
            if (i > 0) {
              i--;
              setDisplayedText(prev => ({
                ...prev,
                [stack]: currentText.substring(0, i)
              }));
            } else {
              clearInterval(eraseInterval);

              // Type new text
              let j = 0;
              const typeInterval = setInterval(() => {
                if (j <= targetText.length) {
                  setDisplayedText(prev => ({
                    ...prev,
                    [stack]: targetText.substring(0, j)
                  }));
                  j++;
                } else {
                  clearInterval(typeInterval);
                  setIsTyping(typing => ({ ...typing, [stack]: false }));
                }
              }, typingSpeed);
            }
          }, typingSpeed / 2);

          return { ...prev, [stack]: nextIndex };
        });
      }, 4000 + (index * 500)); // Increased interval to allow for typing animation
    });

    return () => {
      Object.values(intervals).forEach(clearInterval);
    };
  }, []);

  const lanternVariants = {
    float: {
      y: [-20, 20],
      transition: {
        y: {
          duration: 4,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut"
        }
      }
    }
  };

  // Update the animation variants for the left content
  const contentVariants = {
    hidden: {
      opacity: 0,
      x: -20,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,  // Reduced from default
        ease: "easeOut",
        when: "beforeChildren",
      },
    },
  };

  const scrollToContact = () => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <HeroSection ref={ref} id="hero">
      <AnimatedBackground>
        <AnimatedGrid />
        <AnimatedOrbs />
        <AnimatedParticles />
        <FloatingElements />
      </AnimatedBackground>
      <ContentWrapper isRTL={i18n.dir() === 'rtl'}>
        <HeroContent
          as={motion.div}
          variants={contentVariants}
          initial="hidden"
          animate="visible"
        >
          <Badge>
            <span>🤖</span> {t('hero.badge')}
          </Badge>
          <Title>
            {t('hero.title.part1')}
            <GradientText> {t('hero.title.part2')}</GradientText>
            <br />
            {t('hero.title.part3')}
          </Title>
          <Description>
            {t('hero.description')}
          </Description>
          <ButtonGroup>
            <PrimaryButton
              as={motion.button}
              onClick={scrollToContact}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t('hero.cta')} <span>→</span>
            </PrimaryButton>
          </ButtonGroup>
          <Stats>
            <StatItem>
              <StatNumber>100+</StatNumber>
              <StatLabel>{t('hero.stats.projects')}</StatLabel>
            </StatItem>
            <StatDivider />
            <StatItem>
              <StatNumber>50+</StatNumber>
              <StatLabel>{t('hero.stats.clients')}</StatLabel>
            </StatItem>
            <StatDivider />
            <StatItem>
              <StatNumber>24/7</StatNumber>
              <StatLabel>{t('hero.stats.support')}</StatLabel>
            </StatItem>
          </Stats>
        </HeroContent>
        <HeroVisual isRTL={i18n.dir() === 'rtl'}>
          <TerminalWrapper>
            <Terminal>
              <BackgroundLayer1 />
              <BackgroundLayer2 />
              <BackgroundLayer3 />
              <TerminalStackLayer1 />
              <TerminalStackLayer2 />
              <TerminalStackLayer3 />
              <TerminalHeader>
                <TerminalDots>
                  <Dot color="#ff5f56" />
                  <Dot color="#ffbd2e" />
                  <Dot color="#27c93f" />
                </TerminalDots>
                <TerminalTitle>webox_ai.sh</TerminalTitle>
              </TerminalHeader>
              <CodeBlock>
                <CodeLine><Purple>import</Purple> <Blue>AI</Blue> <Purple>from</Purple> <Green>'webox/ai'</Green>;</CodeLine>
                <CodeLine />
                <CodeLine><Purple>const</Purple> <Yellow>project</Yellow> = <Blue>AI</Blue>.<Yellow>createProject</Yellow>{'({'}</CodeLine>
                <CodeLine>  name: <Green>'Your Next Website'</Green>,</CodeLine>
                <CodeLine>  tech: {'{'}</CodeLine>
                <CodeLine>    frontend: <Green>'{displayedText.frontend}{isTyping.frontend ? '|' : ''}'</Green>,</CodeLine>
                <CodeLine>    backend: <Green>'{displayedText.backend}{isTyping.backend ? '|' : ''}'</Green>,</CodeLine>
                <CodeLine>    database: <Green>'{displayedText.database}{isTyping.database ? '|' : ''}'</Green></CodeLine>
                <CodeLine>  {'}'},</CodeLine>
                <CodeLine>  timeline: <Green>'10x Faster'</Green>,</CodeLine>
                <CodeLine>  aiPowered: <Blue>true</Blue></CodeLine>
                <CodeLine>{'});'}</CodeLine>
                <CodeLine />
                <StatusLine>🚀 Initializing AI-powered development...</StatusLine>
              </CodeBlock>
            </Terminal>
          </TerminalWrapper>
        </HeroVisual>
      </ContentWrapper>
    </HeroSection>
  );
};

const HeroSection = styled.section`
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 8rem 2rem;
  background: linear-gradient(
    135deg,
    #0d1117 0%,
    #161b22 50%,
    #0d1a14 100%
  );

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(88, 166, 255, 0.15),
      transparent 60%
    ),
    radial-gradient(
      circle at bottom left,
      rgba(59, 239, 125, 0.15),
      transparent 60%
    );
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      to bottom,
      transparent,
      rgba(59, 239, 125, 0.08),
      rgba(13, 17, 23, 0.15)
    );
    pointer-events: none;
  }

  @media (max-width: 768px) {
    padding: 6rem 1.5rem;
  }
`;

const HeroContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: ${props => props.isRTL ? 'row-reverse' : 'row'};
  justify-content: space-between;
  align-items: center;
  gap: 4rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    padding: 0 1.5rem;
    text-align: center;
  }

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const HeroContent = styled(motion.div)`
  max-width: 600px;
  width: 100%;

  @media (max-width: 1024px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const HeroVisual = styled.div`
  flex: 1;
  position: relative;
  perspective: 2000px;
  direction: ltr;
  transform: none;

  @media (max-width: 1024px) {
    width: 100%;
    transform: none;
  }
`;

const TerminalWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  transform: perspective(2000px) rotateY(-20deg) rotateX(10deg);
  transform-style: preserve-3d;
  animation: terminalFloat 6s ease-in-out infinite;
  direction: ltr;

  @keyframes terminalFloat {
    0%, 100% {
      transform: perspective(2000px) rotateY(-20deg) rotateX(10deg) translateY(0);
    }
    50% {
      transform: perspective(2000px) rotateY(-20deg) rotateX(10deg) translateY(-20px);
    }
  }
`;

const AnimatedBackground = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0.4;
  z-index: 0;
  overflow: hidden;
`;

const AnimatedGrid = styled.div`
  position: absolute;
  inset: 0;
  background-image: 
    linear-gradient(rgba(88, 166, 255, 0.15) 1px, transparent 1px),
    linear-gradient(90deg, rgba(88, 166, 255, 0.15) 1px, transparent 1px);
  background-size: 50px 50px;
  transform: perspective(1000px) rotateX(60deg);
  animation: gridMove 20s linear infinite;
  opacity: 0.5;
  z-index: 1;

  @keyframes gridMove {
    0% { transform: perspective(1000px) rotateX(60deg) translateY(0); }
    100% { transform: perspective(1000px) rotateX(60deg) translateY(50px); }
  }
`;

const AnimatedOrbs = styled.div`
  position: absolute;
  inset: 0;
  filter: blur(100px);

  &::before, &::after {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    opacity: 0.2;
  }

  &::before {
    background: rgba(88, 166, 255, 0.5);
    top: 10%;
    left: 20%;
    animation: orbFloat1 15s ease-in-out infinite;
  }

  &::after {
    background: rgba(59, 239, 125, 0.5);
    bottom: 20%;
    right: 20%;
    animation: orbFloat2 20s ease-in-out infinite;
  }

  @keyframes orbFloat1 {
    0%, 100% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-50px, 30px);
    }
  }

  @keyframes orbFloat2 {
    0%, 100% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(50px, -30px);
    }
  }
`;

const AnimatedParticles = styled.div`
  position: absolute;
  inset: 0;
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: radial-gradient(
      circle at center,
      rgba(88, 166, 255, 0.1) 1px,
      transparent 1px
    );
    background-size: 40px 40px;
    animation: particlePulse 4s ease-in-out infinite;
  }

  @keyframes particlePulse {
    0%, 100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
`;

const FloatingElements = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;

  &::before, &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(88, 166, 255, 0.1);
    animation: float 10s infinite;
  }

  &::before {
    top: 20%;
    left: 20%;
    animation-delay: 0s;
  }

  &::after {
    top: 60%;
    right: 20%;
    animation-delay: -5s;
  }

  @keyframes float {
    0%, 100% {
      transform: translate(0, 0) rotate(0deg);
    }
    25% {
      transform: translate(100px, 50px) rotate(90deg);
    }
    50% {
      transform: translate(50px, 100px) rotate(180deg);
    }
    75% {
      transform: translate(-50px, 50px) rotate(270deg);
    }
  }
`;

const Badge = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.3rem 0.6rem;
  border-radius: 14px;
  color: #58a6ff;
  font-size: 0.75rem;
  margin-bottom: 1.2rem;
  border: 1px solid rgba(88, 166, 255, 0.2);

  span {
    font-size: 0.9rem;
  }
`;

const Title = styled.h1`
  font-size: 3.5rem;
  color: #ffffff;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: -0.02em;

  @media (max-width: 1024px) {
    font-size: 2.8rem;
  }

  @media (max-width: 768px) {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(135deg, #58a6ff 0%, #38ef7d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 280px; // Control button width on mobile
    margin: 2rem auto;
  }
`;

const PrimaryButton = styled(motion.button)`
  background: linear-gradient(135deg, #58a6ff, #3bef7d);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  transition: transform 0.2s ease;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding: 0.875rem 1.5rem;
    font-size: 0.95rem;
  }

  span {
    font-size: 1.2em;
  }
`;

const SecondaryButton = styled.button`
  padding: 0.8rem 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border: 1px solid rgba(240, 246, 252, 0.1);
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const GithubIcon = styled.i`
  font-size: 1.2rem;
`;

const Stats = styled.div`
  display: inline-flex;
  gap: 1.2rem;
  margin-top: 1.2rem;
  padding: 0.8rem 1.2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: auto;
`;

const StatItem = styled.div`
  text-align: center;
  min-width: 120px;
`;

const StatNumber = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #58a6ff, #38ef7d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.15rem;
`;

const StatLabel = styled.div`
  font-size: 0.7rem;
  color: #a8b3cf;
  text-transform: uppercase;
  letter-spacing: 0.4px;
`;

const StatDivider = styled.div`
  width: 1px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);

  @media (max-width: 480px) {
    display: none;
  }
`;

const Terminal = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(26, 30, 36, 0.95),
    rgba(22, 27, 34, 0.95)
  );
  border-radius: 10px;
  overflow: visible;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  transform-style: preserve-3d;
  direction: ltr;
  text-align: left;
`;

const TerminalHeader = styled.div`
  background: linear-gradient(
    to bottom,
    #2d333b,
    #252a30
  );
  padding: 0.8rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(88, 166, 255, 0.1);
  box-shadow: 
    0 1px 2px rgba(0, 0, 0, 0.1),
    0 1px 0 rgba(255, 255, 255, 0.05);
`;

const TerminalDots = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => props.color};
  box-shadow: 
    inset 0 1px 2px rgba(0, 0, 0, 0.2),
    0 1px 1px rgba(255, 255, 255, 0.1);
`;

const TerminalTitle = styled.div`
  color: #8b949e;
  font-size: 0.9rem;
  margin-left: 1rem;
  font-family: 'Fira Code', monospace;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
`;

const CodeBlock = styled.div`
  padding: 1.5rem;
  font-family: 'Fira Code', monospace;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #c9d1d9;
  background: linear-gradient(
    to bottom,
    rgba(26, 30, 36, 0.95),
    rgba(22, 27, 34, 0.95)
  );
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  
  & > * {
    opacity: 0;
    animation: fadeInCode 0.5s ease forwards;
  }

  ${Array.from({ length: 15 }, (_, i) => `
    & > *:nth-of-type(${i + 1}) {
      animation-delay: ${0.8 + i * 0.1}s;
    }
  `)}
  
  @keyframes fadeInCode {
    from {
      opacity: 0;
      transform: translateX(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: 
      radial-gradient(
        circle at top right,
        rgba(88, 166, 255, 0.08),
        transparent 70%
      ),
      radial-gradient(
        circle at bottom left,
        rgba(59, 239, 125, 0.08),
        transparent 70%
      );
  }
  direction: ltr;
  text-align: left;
`;

const CodeLine = styled.div`
  margin-bottom: 0.5rem;
  padding-left: ${props => props.indent ? '2rem' : '0'};
  position: relative;
  z-index: 1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
`;

const Comment = styled.span`
  color: #8b949e;
  margin-left: 0.5rem;
`;

const StatusLine = styled.div`
  color: #7ee787;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    background: currentColor;
    border-radius: 50%;
    display: inline-block;
    animation: blink 1s infinite;
  }

  @keyframes blink {
    50% { opacity: 0; }
  }
`;

const Purple = styled.span`
  color: #ff79c6;
`;

const Blue = styled.span`
  color: #58a6ff;
`;

const Green = styled.span`
  color: #7ee787;
`;

const Yellow = styled.span`
  color: #ffa657;
`;

const Cursor = styled.span`
  animation: blink 1s step-end infinite;

  @keyframes blink {
    from, to {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
`;

const BackgroundLayer1 = styled.div`
  position: absolute;
  inset: -4px;
  background: linear-gradient(135deg, 
    rgba(88, 166, 255, 0.35), 
    rgba(59, 239, 125, 0.35)
  );
  border-radius: 14px;
  z-index: -1;
  filter: blur(10px);
  opacity: 0.8;
`;

const BackgroundLayer2 = styled.div`
  position: absolute;
  inset: -8px;
  background: linear-gradient(135deg, 
    rgba(88, 166, 255, 0.25), 
    rgba(59, 239, 125, 0.25)
  );
  border-radius: 18px;
  z-index: -2;
  filter: blur(20px);
  opacity: 0.6;
`;

const BackgroundLayer3 = styled.div`
  position: absolute;
  inset: -12px;
  background: linear-gradient(135deg, 
    rgba(88, 166, 255, 0.15), 
    rgba(59, 239, 125, 0.15)
  );
  border-radius: 22px;
  z-index: -3;
  filter: blur(30px);
  opacity: 0.4;
`;

const TerminalStackLayer1 = styled.div`
  position: absolute;
  top: -5px;
  right: -15px;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg,
    rgba(88, 166, 255, 0.15),
    rgba(59, 239, 125, 0.15)
  );
  border-radius: 10px;
  transform: translateZ(-10px);
  z-index: -1;
  left: auto;
  opacity: 0;
  animation: 
    fadeInLayer 0.5s ease forwards,
    stackFloat1 6s ease-in-out infinite;
  animation-delay: 0.2s, 0.3s;
`;

const TerminalStackLayer2 = styled.div`
  position: absolute;
  top: -10px;
  right: -25px;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg,
    rgba(88, 166, 255, 0.1),
    rgba(59, 239, 125, 0.1)
  );
  border-radius: 10px;
  transform: translateZ(-20px);
  z-index: -2;
  left: auto;
  opacity: 0;
  animation: 
    fadeInLayer 0.5s ease forwards,
    stackFloat2 6s ease-in-out infinite;
  animation-delay: 0.3s, 0.4s;
`;

const TerminalStackLayer3 = styled.div`
  position: absolute;
  top: -15px;
  right: -35px;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg,
    rgba(88, 166, 255, 0.05),
    rgba(59, 239, 125, 0.05)
  );
  border-radius: 10px;
  transform: translateZ(-30px);
  z-index: -3;
  left: auto;
  opacity: 0;
  animation: 
    fadeInLayer 0.5s ease forwards,
    stackFloat3 6s ease-in-out infinite;
  animation-delay: 0.4s, 0.5s;
`;

const keyframes = `
  @keyframes fadeInLayer {
    from {
      opacity: 0;
      transform: translateZ(-50px) translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateZ(-30px) translateX(0);
    }
  }

  @keyframes stackFloat1 {
    0%, 100% {
      transform: translateZ(-10px) translateY(0);
    }
    50% {
      transform: translateZ(-10px) translateY(-22px);
    }
  }

  @keyframes stackFloat2 {
    0%, 100% {
      transform: translateZ(-20px) translateY(0);
    }
    50% {
      transform: translateZ(-20px) translateY(-24px);
    }
  }

  @keyframes stackFloat3 {
    0%, 100% {
      transform: translateZ(-30px) translateY(0);
    }
    50% {
      transform: translateZ(-30px) translateY(-26px);
    }
  }
`;

const style = document.createElement('style');
style.textContent = keyframes;
document.head.appendChild(style);

export default Hero; 